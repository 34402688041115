import React from "react";
import { Stack } from "@fluentui/react";
import logo from "./assets/dealer2.jpg";
import "./App.css";
import { Depths } from "@fluentui/theme";

export const App: React.FunctionComponent = () => {
  return (
    <Stack>
      <Stack style={{paddingBottom: "30px"}}>
        <img className="App-logo" src={logo} alt="dealerpic" />
      </Stack>
      <Stack className="vehicle-container" horizontal wrap horizontalAlign="center" verticalAlign="center">
        <Stack className="vehicle-card">
          Square 1
        </Stack>
        <Stack className="vehicle-card" style={{ boxShadow: Depths.depth8 }}>
          Square 2
        </Stack>
        <Stack className="vehicle-card" style={{ boxShadow: Depths.depth8 }}>
          Square 3
        </Stack>
        <Stack className="vehicle-card" style={{ boxShadow: Depths.depth8 }}>
          Square 4
        </Stack>
        <Stack className="vehicle-card" style={{ boxShadow: Depths.depth8 }}>
          Square 5
        </Stack>
        <Stack className="vehicle-card" style={{ boxShadow: Depths.depth8 }}>
          Square 6
        </Stack>
        <Stack className="vehicle-card" style={{ boxShadow: Depths.depth8 }}>
          Square 7
        </Stack>
        <Stack className="vehicle-card" style={{ boxShadow: Depths.depth8 }}>
          Square 8
        </Stack>
        <Stack className="vehicle-card" style={{ boxShadow: Depths.depth8 }}>
          Square 9
        </Stack>
      </Stack>
      <Stack className="footer-container">
        Footer
      </Stack>
    </Stack>
  );
};